/*******************************
        User Overrides
*******************************/
.ui.dropdown .menu.transition {
   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}

.ui.dropdown .menu .item:hover, .ui.dropdown .menu .item:hover > div{
   background: #f1f1f1;
}


.ui.selection.dropdown[readonly] {
  background: @inputReadOnlyBackgroundColor
}

.ui.selection.dropdown {
  min-width: 30px;
}

.ui.selection.active.dropdown:hover, .ui.selection.dropdown:hover {
  border-color: @primaryColor;
}

.ui.dropdown:not(.button) > .default.text, .ui.default.dropdown:not(.button) > .text {
  color: @lightGrayTextColor !important;
}