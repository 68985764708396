/*******************************
        User Overrides
*******************************/
.ui.accordion:hover,
.ui.accordion:focus,
.ui.accordion.active-acc {
  //border: 1px solid @borderColor !important;
  //box-shadow: 0 2px 4px 0 #f5f5f5 !important;
}

.ui.accordion:not(:hover) .content {
//   border: 1px solid @borderColor;
}

.ui.styled.accordion{
  width: 100%;
  border-radius: 0px;
  .content{
    border-top: 1px solid #e9eced;
    padding: 32px;
  }
}

