/*******************************
         Site Overrides
*******************************/
.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  width: 17px;
  height: 17px;
  top: 0px;
  left: -1px;
}

.ui.radio.checkbox input:focus:checked ~ .box:before,
.ui.radio.checkbox input:focus:checked ~ label:before {
  box-shadow: 0px 0px 0px @focusShadowSize @shadowPrimaryColor;
}


.ui.checkbox .box:hover::before,
.ui.checkbox label:hover::before {
box-shadow: 0px 0px 0px @focusShadowSize rgba(0,0,0,0.03);
// background: rgba(0,0,0,0.1);
// border: 1px solid #ccc;
}

.ui.checkbox .box:active::before,
.ui.checkbox label:active::before {
  box-shadow: 0px 0px 0px @focusShadowSize rgba(0,0,0,0.05);
  background: @shadowPrimaryColor
}


.ui.checkbox.active .box:hover::before,
.ui.checkbox.active label:hover::before {
box-shadow: 0px 0px 0px @focusShadowSize @shadowLightPrimaryColor;
// background: rgba(0,0,0,0.1);
// border: 1px solid #ccc;
}

.ui.checkbox:not(.radio) .box:before,
.ui.checkbox:not(.radio) label:before {
  background: white;
  border: 1px solid #eee;
}

.ui.checkbox.active .box:active::before,
.ui.checkbox.active label:active::before {
box-shadow: 0px 0px 0px @focusShadowSize @shadowDarkPrimaryColor;
// background: rgba(0,0,0,0.1);
// border: 1px solid #ccc;
}
.ui.checkbox:not(.radio):not(.toggle) input:checked ~ .box:after,
.ui.checkbox:not(.radio):not(.toggle) input:checked ~ label:after {
  content: ' ';
  background: url(../assets/images/icons/checked.svg);
  background-size: 26px;
  transform: none;
  top: -4px;
  left: -4px;
  width: 26px;
  height: 26px;
}