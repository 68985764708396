/*******************************
         Site Overrides
*******************************/
.ui.input > input {
  background: #fafafa;
}

.ui.input.rect-search > input {
  background: white;
  padding-left: 36px;
}

.ui.input.rect-search.round-search > input {
  background: white;
  border-radius: 16px;
}

.ui.input input:read-only,
.field input:read-only  {
	background: @inputReadOnlyBackgroundColor !important;
}