/*******************************
         Site Overrides
*******************************/
.ui.label {
  font-weight: 300;
  border-radius: 2px;
}

.ui.labeld.input > .label {
  background: none;
  border: 1px solid #eaeaea;
  font-weight: 300;
}

.ui.label.circular .icon {
  margin: 0 !important;
}

.ui.label.simple-label {
  border-radius: 20px;
}