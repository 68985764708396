/*******************************
         Site Overrides
*******************************/
.ui.menu, .ui-vertical-menu {
  border: none;
  box-shadow: none;
}

.ui.menu .item {
  transition: background-color 0.3s, color 0.3s;
}

/*******************************
          Vertical menu
*******************************/
.ui.vertical.menu {
  box-shadow: none;
}

.main-side-menu .ui.vertical.menu {
  width: 80px;
  text-align: center;
}

.ui.vertical.menu .item {
  background: none;
  border-radius: @borderRadius;
  border: none;
  border-bottom: none;
  border-top: none;
  box-shadow: none;
  border: none;
  color: @lightGrayTextColor;
}

.side-bar-content .ui.vertical.menu.sub-side-menu .item{
  padding: 0;
}

.main-side-menu .ui.vertical.menu .item {
  text-align: center;
}

.ui.vertical.menu .item::before {
  background: none;
}

.main-side-menu .ui.vertical.menu .item:hover {
  background: none !important;
}


/*******************************
          Sub Side Menu
*******************************/
.side-bar-content .ui.vertical.menu {
  padding-bottom: 10px;
  margin-right: 0px;
  width: auto;
}

.side-bar-content .ui.vertical.menu .item {
  color: @textColor;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 20px 10px 30px;
}

.side-bar-content .ui.vertical.menu .active.item {
  background-color: #e4eaea;
  font-weight: 600;
}

/** 현재 페이지인 항목**/
.side-bar-content .ui.vertical.menu .active.item span div {
  // color: @primaryColor !important;
}

.side-bar-content .ui.vertical.menu .item:hover {
  background-color: #f0f3f3;
}

/** .ui.vertical.menu .active.item {
  box-shadow: 0 0px 0px 3px rgba(56, 159, 162, 0.2);
  border: solid 2px #389fa2;
  margin: -2px;
  background: none;
  color: @selectedTextColor;
  z-index: 100;
}

.ui.vertical.menu:first-child .active.item,
.ui.vertical.menu:last-child .active.item {
  border-radius: @borderRadius;
}
*/

.ui.menu .active.item:hover {
  background: none;
}

/*******************************
          Secondary menu
*******************************/


.ui.secondary.pointing.menu {
  padding: 10px 10px 0px 10px;
  margin-bottom: 0px;
}
.ui.secondary.pointing.menu .item {
  font-size: 16px;
  line-height: 28px;
}
.ui.secondary.pointing.menu .active.item {
  font-weight: normal;
}

.ui.secondary.pointing.menu .active.item span::after {
  position: absolute;
  content: '';
  border-bottom: solid 2px #389fa2;
  width: 29px;
  bottom: 0px;
  left: 12px;
}


/*******************************
          Pagination
*******************************/
.ui.pagination.menu {
  box-shadow: 0 2px 4px 0 #f5f5f5;
  border-radius: 2px;
  height: 34px;
  background-color: #ffffff;
  border: solid 1px #ececec;
}

.ui.pagination.menu .item {
  text-align: center;
  font-size: 16px;
}

.ui.pagination.menu .active.item {
  background-color: white;
  text-decoration: underline;
  color: @primaryColor;
}


/*******************************
          Hover Options
*******************************/
.hover-ellipsis-menu.menu .item .ellipsis-more-button {
  visibility: hidden;
}

.hover-ellipsis-menu.menu .item:hover .ellipsis-more-button,
.hover-ellipsis-menu.menu .item.active .ellipsis-more-button {
  visibility: visible;
}
