/*******************************
         Site Overrides
*******************************/
.ui.sidebar {
  background: rgba(0,0,0, 0);
}
.ui.sidebar.overflow-y-initial {
  overflow-y: initial !important;
}

.ui.sidebar.left {
  visibility: visible;
  transition: margin-left 250ms ease-out,transform 250ms ease-out;
}

.ui.sidebar.left:not(.visible) {
  transform: none;
  margin-left: -200px !important;
}

.ui.visible.thin.left.sidebar ~ .fixed, .ui.visible.thin.left.sidebar ~ .pusher {
  transform: none;
}

.pushable {
  display: flex;
}

.pushable .pusher {
  flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: auto;
}

.ui.sidebar .side-bar-content::-webkit-scrollbar {
  display: none;
}

.ui.sidebar:hover .side-bar-content::-webkit-scrollbar {
  display: block;
}