/*******************************
         Site Overrides
*******************************/
.ui.relaxed.grid {
  margin-left: -40px;
  margin-top: -40px;
  margin-right: -40px;
  margin-bottom: -40px;
}
.ui.relaxed.grid .row{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.ui.relaxed.grid .row .column{
  padding-left: 20px;
  padding-right: 20px;
}

.ui.grid .column.small{
  width: 33.3%
}

.ui.grid .column.medium {
  width: 50%,
}

.ui.grid .column.large {
  width: 100%,
}