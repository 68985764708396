/*******************************
         Site Overrides
*******************************/
.ui.table thead th {
  font-size: 12px;
}

.ui.selectable.table tr {
  cursor: pointer;
}

.chart-grid .ui.table th {
  padding: 0px 15px;
}

.chart-grid .ui.table tbody tr td.selectable:hover {
  background: white;
}
.ui.table:first-child {
  margin-top: 1em;
}