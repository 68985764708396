/*******************************
         Site Overrides
*******************************/
.ui.button {
  letter-spacing: -0.5px;
  transition: opacity 0.3s, box-shadow 0.2s, background-color 0.3s;
}
.ui.button:not(.red):not(.blue):not(.orange):not(.yellow):not(.olive):not(.green):not(.teal):not(.violet):not(.purple):not(.pink):not(.brown):not(.grey):not(.primary):not(.secondary) {
  border: @border;
  background: white;
}

.ui.basic.buttons .button,
.ui.basic.button {
  border: @border;
}
.ui.big.button, .ui.big.buttons .button{
  padding: 18px 32px;
  font-size: 14px;
  line-height: 20px;
}

.ui.modal .actions .button, .ui.large.button, .ui.large.button .button {
  font-size: 14px;
  padding: 10px 25px;
  line-height: 20px;
}

.ui.button:not(.dropdown):hover {
  opacity: 0.4;
}

.ui.huge.button, .ui.huge.buttons button {
  padding: 24px 36px
}