/*******************************
         Site Overrides
*******************************/

.ui.dimmer .ui.modal .loader:before {
  border-color: rgba(0,0,0,.1);
}

.ui.dimmer .ui.modal .loader:after {
  border-color: #ABABAB transparent transparent;
}
