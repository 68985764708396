/*******************************
         Site Overrides
*******************************/
.ui.modal .close.icon {
  top: 15px;
  right: 15px;
  background: url(../assets/images/icons/x.svg);
  background-repeat: no-repeat;
  background-position: center;
}


.ui.modal .close.icon:hover {
  opacity: 0.4;  
}

.ui.modal .close.icon::before {
  content: none;
}

.ui.modal.fullscreen .scrolling.content {
  max-height: calc(100vh - 10em);
}